import { useMemo, useSyncExternalStore } from 'react'

// NOTE: DO NOT USE FOR ANYTHING NEW. Necessary for charts atm.

// NOTE: This now doesnt match to breakpoints.tablet
const desktopQuery = 'min-width: 768px'

/*
 * NOTE:
 * The problem with useMatchMedia/isMobile is that on server side the initial state is more or less impossible to deduce
 * (there's version which look at UserAgent from request, which may result in better or worse results where you can never oversee how it really
 * works for end users with all the various devices).
 * This causes initial state to be wrong for 100% of the time for the other half of the match.
 * Set initial state based on min-query or max. Prefer min to match. Min is most likely "larger" displays where
 * affected content wont be shown on initial load.
 * If someone uses isMobile in fold, it's their own shame. It will never work as well as CSS.
 *
 * TLDR: if you use this via useIsMobile, it will default to false on SSR
 */
export function useMediaMatch(mediaQuery: string, ssrValue = false): boolean {
  const query =
    mediaQuery.startsWith('min-') || mediaQuery.startsWith('max-')
      ? `(${mediaQuery})`
      : mediaQuery
  const [getSnapshot, subscribe] = useMemo(() => {
    return [
      () => window.matchMedia(query).matches,
      (notify: () => void) => {
        const mediaQueryList = window.matchMedia(query)
        mediaQueryList.addEventListener('change', notify)

        return () => {
          mediaQueryList.removeEventListener('change', notify)
        }
      },
    ]
  }, [query])

  return useSyncExternalStore(subscribe, getSnapshot, () => ssrValue)
}

export const useIsMobile = (ssrValue = false) => {
  return !useMediaMatch(desktopQuery, !ssrValue)
}
