import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type MetricConfiguration } from "@tokenterminal/tt-analytics-api-types/dist/api/metrics"
import { atom, type Atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { metricsConfigurationsAtom } from "./meta-atoms"

const medianAggregateMetrics = new Set([
  "transaction-fee-average",
  "block-time",
  "arpu",
  "afpu",
  "trading-volume-avg-per-trade-count",
  "trading-volume-avg-per-user",
])

function isAggregateMetric(metricConfig: MetricConfiguration) {
  return metricConfig.metric_id !== "price" && metricConfig.format !== "ratio"
}

export const isMedianMetric = (metricConfig: MetricConfiguration) => {
  return (
    metricConfig.format === "ratio" ||
    medianAggregateMetrics.has(metricConfig.slug)
  )
}

export const getTooltipOptionsFromChartSettingsAtom = atomFamily(function (
  chartSettingsAtom: Atom<Promisable<Array<CustomChartSerie>>>
) {
  return atom(async (get) => {
    const chartSettings = await get(chartSettingsAtom)
    if (chartSettings.length !== 1) {
      return undefined
    }

    const metricConfigurations = toDictionary(
      unwrapWithData(await get(metricsConfigurationsAtom)),
      "slug"
    )

    const chartSetting = chartSettings[0]!
    const metricConfig = metricConfigurations.get(chartSetting.metric)
    if (!metricConfig) {
      return undefined
    }

    return {
      sort: true,
      showTotal:
        isAggregateMetric(metricConfig) && !isMedianMetric(metricConfig),
    }
  })
})
