import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type Atom, useAtomValue } from "jotai"
import { useMemo } from "react"
import { getYAxisOptionsFromChartSettingsAtom } from "../store/chart/yaxis-options-atom"

export function useYAxisOptions(
  chartSettingsAtom: Atom<Promisable<Array<CustomChartSerie>>>
) {
  const yAxisOptions = useAtomValue(
    useMemo(
      () => getYAxisOptionsFromChartSettingsAtom(chartSettingsAtom),
      [chartSettingsAtom]
    )
  )

  return yAxisOptions
}
