import EmptyState from '@tokenterminal/ui/Chart/EmptyState'

const NoDataEmptyState = () => {
  return (
    <EmptyState
      headingText="No data to display"
      bodyText="The configuration or selected filters didn't return any data."
      size="small"
    />
  )
}

export default NoDataEmptyState
