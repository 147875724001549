import { Box } from "@tokenterminal/ui/Box"
import { HeadingWithCapsize } from "@tokenterminal/ui/Heading/Heading"
import { Stack } from "@tokenterminal/ui/Stack"
import { TextWithCapsize } from "@tokenterminal/ui/Text/Text"
import { type Atom, useAtomValue } from "jotai"
import { unwrap } from "jotai/utils"
import { useMemo } from "react"

type ChartHeadingProps = {
  showTitle: boolean
  showDescription: boolean
  titleAtom: Atom<Promisable<string>>
  descriptionAtom: Atom<Promisable<string | null>>
}

export function ChartHeading({
  showTitle,
  showDescription,
  titleAtom,
  descriptionAtom,
}: ChartHeadingProps) {
  const title = useAtomValue(
    useMemo(() => unwrap(titleAtom, () => null), [titleAtom])
  )
  const description = useAtomValue(
    useMemo(() => unwrap(descriptionAtom, () => null), [descriptionAtom])
  )

  return (
    <Stack
      gap="2x"
      style={{
        // Leaving some hardcoded room for buttons on the right
        width: "calc(100% - 55px)",
      }}
    >
      <Box display={showTitle ? "block" : "none"}>
        <HeadingWithCapsize
          level={2}
          as="h1"
          variant="primary"
          width="100%"
          fontWeight="600"
        >
          {title}
        </HeadingWithCapsize>
      </Box>
      <Box display={showDescription ? "block" : "none"}>
        <TextWithCapsize size="14" variant={"secondary"}>
          {description}
        </TextWithCapsize>
      </Box>
    </Stack>
  )
}
