import {
  tooltipItemCss,
  headerCss,
  tooltipTimestampCss,
  tooltipContainerv2Css,
  tooltipRowCss,
} from './Tooltip.css'
import { SERIE_STACK } from '.'
import type { Point } from 'highcharts'

export type TooltipOptions = {
  sort: boolean
  showTotal: boolean
}

export function formatTooltip(
  this: Highcharts.TooltipFormatterContextObject,
  tooltip: Highcharts.Tooltip,
  tooltipOptions: TooltipOptions = { sort: false, showTotal: true }
): Array<string> | string {
  let header
  let footerTimestamp
  let sum = 0

  tooltipOptions.sort = true
  if (tooltipOptions.sort && this.points) {
    this.points?.sort((a, b) => (b.y ?? -1) - (a.y ?? -1))
  }

  if (this.points) {
    this.points.forEach((point) => {
      const isPercentageShare =
        // @ts-ignore
        point.series.userOptions.stacking === SERIE_STACK.PERCENTAGE

      if (isPercentageShare) {
        sum += point.percentage || 0
      } else {
        sum += point.y || 0
      }
    })
  }

  const formatted = tooltip.defaultFormatter.call(this, tooltip)
  const [firstField, ...fields] = formatted

  // If first field is a string of text and not a timestamp, render as header.
  if (Number.isNaN(new Date(firstField).getDate())) {
    header = firstField
  } else {
    const isQuarterGranularity =
      tooltip.chart.series?.[0]?.userOptions?.custom?.granularity === 'quarter'

    if (isQuarterGranularity && Number.isInteger(this.x)) {
      const parsedDate = new Date(this.x as number)
      const endOfQuarter = new Date(parsedDate)
      endOfQuarter.setMonth(parsedDate.getUTCMonth() + 2)
      const quarter = Math.floor((parsedDate.getUTCMonth() + 3) / 3)

      footerTimestamp = `Q${quarter} - ${parsedDate.toLocaleString('en-US', {
        month: 'short',
        timeZone: 'UTC',
      })} - ${endOfQuarter.toLocaleString('en-US', {
        month: 'short',
        timeZone: 'UTC',
      })} ${parsedDate.toLocaleString('en-US', {
        year: '2-digit',
        timeZone: 'UTC',
      })}`
    } else {
      footerTimestamp = firstField
    }
  }

  const tooltipChunks = [`<div class="${tooltipContainerv2Css}">`]

  if (header) {
    tooltipChunks.push(`<p class="${headerCss}">${header}</p>`)
  }

  if (footerTimestamp) {
    tooltipChunks.push(
      `<p class="${tooltipTimestampCss}">${footerTimestamp}</p>`
    )
  }

  tooltipChunks.push('<div class="tooltip-items-container">')
  for (let i = 0; i < fields.length - 1; i++) {
    if (fields[i]) {
      tooltipChunks.push(`<div class="${tooltipItemCss}">${fields[i]}</div>`)
    }
  }
  tooltipChunks.push('</div>')

  if (tooltipOptions.showTotal && fields.filter(Boolean).length > 1) {
    const totalTooltip = (
      tooltip as unknown as {
        bodyFormatter: (items: Array<Point>) => Array<string>
      }
    ).bodyFormatter([
      {
        ...this.points?.[0],
        // @ts-ignore
        point: {
          ...this.points?.[0]?.point,
          color: null,
          y: sum,
          percentage: sum,
          series: {
            tooltipOptions: {},
            name: 'Total',
          },
        },
      },
    ])

    tooltipChunks.push(`<div class="${tooltipRowCss}">${totalTooltip}</div>`)
  }

  tooltipChunks.push('</div>')

  return tooltipChunks.join('')
}

// no ratio, no price
