// import { type ChartProps } from "./Chart"

import { useEffect } from "react"
import { Chart, type ChartProps } from "./Chart"
import { fetchConfigByIdAtom } from "./store/fetch-config-atom"

type ChartWithConfigIdProps = Omit<ChartProps, "configAtom"> & { id: string }
export function ChartWithConfigId({ id, ...props }: ChartWithConfigIdProps) {
  useEffect(() => {
    return () => {
      // cleanup memory allocation
      fetchConfigByIdAtom.remove(id)
    }
  }, [id])

  return <Chart key={id} configAtom={fetchConfigByIdAtom(id)} {...props} />
}
