export function formatDate(date: Date, niceFormat = false) {
  let day = String(date.getUTCDate())
  const year = String(date.getUTCFullYear())
  if (niceFormat) {
    return `${day} ${date.toLocaleDateString('en-US', {
      month: 'short',
    })}, ${year}`
  }
  let month = String(date.getUTCMonth() + 1)

  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }

  return [year, month, day].join('-')
}

/*
 * Basically floors timestamp to 1 unit
 * Alternative would be to use "ms" package for example. However that costs more in size, covers things we dont need at least now and
 * might not support some use case which we might have. This fn. is pretty lightweight and should be fairly easy to scale if needed
 *
 * TODO: Build unit test for this
 */
const defaultTimeFormatter = (
  time: number,
  unit: 'ms' | 's' | 'm' | 'h',
): string => {
  switch (unit) {
    case 'ms':
      return `${Math.round(time)}${unit}`
    default:
      return `${time.toFixed(1)}${unit}`
  }
}

export const formatTime = (
  timeInMs: number,
  formatter = defaultTimeFormatter,
) => {
  try {
    const seconds = timeInMs / 1000
    const minutes = seconds / 60
    const hours = minutes / 60

    if (seconds < 1) {
      return formatter(timeInMs, 'ms')
    }

    if (minutes < 1) {
      return formatter(seconds, 's')
    }

    if (hours < 1) {
      return formatter(minutes, 'm')
    }

    return formatter(hours, 'h')
  } catch (error) {
    return '-'
  }
}

export function calculateTimeAgoValue(
  fromTimestamp: number,
  format: 'normal' | 'short' = 'normal',
): string {
  const diffInMs = Math.abs(fromTimestamp - new Date().getTime())
  let daysDifference = Math.round(diffInMs / (1000 * 60 * 60 * 24))

  const years = Math.floor(daysDifference / 365)
  daysDifference = daysDifference % 365
  const days = daysDifference

  let timeAgoBuffer = ``
  if (years) {
    if (format === 'short') {
      if (days) {
        return `${(years + days / 365).toFixed(1)}y`
      } else {
        return `${years}y`
      }
    } else {
      timeAgoBuffer += `${years}y`
    }
  }
  if (days) {
    timeAgoBuffer += `${timeAgoBuffer ? ' ' : ''}${days}d`
  }

  if (!timeAgoBuffer) {
    if (format === 'short') {
      return '' // Today, but "Today ago" is not neat
    }
    return formatTime(diffInMs, (time, unit) => `${Math.round(time)}${unit}`)
  }

  return timeAgoBuffer
}
