import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { Row } from "@tokenterminal/ui/Box"
import { ChartContext } from "@tokenterminal/ui/Chart/ChartContext"
import { Divider } from "@tokenterminal/ui/Divider"
import { Stack } from "@tokenterminal/ui/Stack"
import { TextWithCapsize } from "@tokenterminal/ui/Text/Text"
import { useAtomValue, type Atom } from "jotai"
import { splitAtom, unwrap } from "jotai/utils"
import { Fragment, memo, Suspense, useContext, useMemo } from "react"
import { availableColors } from "../../constants/available-colors"
import { getSerieIdsFromChartSettingAtom } from "../../store/chart/serie-ids-atom"
import { getLabelsFromChartSettingAtom } from "../../store/chart/series-labels-atom"
import { LegendItem } from "./LegendItem"

type LegendProps = {
  onVisibilityChange?: (id: string, isVisible: boolean) => void
  serieSettingsAtom: Atom<Promisable<Array<CustomChartSerie>>>
}

function LegendRow({
  index,
  settingsAtom,
  onVisbilityChange,
}: {
  index: number
  settingsAtom: Atom<CustomChartSerie>
  onVisbilityChange?: (serieId: string, visibility: boolean) => void
}) {
  const settings = useAtomValue(settingsAtom)
  const { chartApi } = useContext(ChartContext)
  const labels = useAtomValue(
    useMemo(
      () =>
        unwrap(getLabelsFromChartSettingAtom(settingsAtom), (prev) => {
          return prev || []
        }),
      [settingsAtom]
    )
  )

  const serieIds = useAtomValue(
    useMemo(
      () =>
        unwrap(getSerieIdsFromChartSettingAtom(settingsAtom), (prev) => {
          return prev || []
        }),
      [settingsAtom]
    )
  )

  return (
    <Stack gap="3x">
      <TextWithCapsize variant="secondary">{settings.title}</TextWithCapsize>
      <Row gap="4x" start flexWrap="wrap">
        {serieIds.map((id, idx) => {
          const currentColor: string =
            settings.colors?.[idx] ??
            availableColors[index + idx] ??
            availableColors[availableColors.length - 1]!
          const label = labels[idx]!

          const isVisible = settings.visible
            ? (settings.visible?.includes?.(id) ?? true)
            : true

          return (
            <LegendItem
              key={id}
              name={label}
              color={currentColor}
              serieType={settings.chart_type}
              isActive={isVisible}
              onMouseOver={() => {
                const currentSerie = chartApi?.get(
                  `serie-${id}`
                ) as Highcharts.Series | null

                // if serie is not visible, we don't trigger this action
                if (!currentSerie?.visible) {
                  return
                }

                serieIds.forEach((serieId) => {
                  const serie = chartApi?.get(
                    `serie-${serieId}`
                  ) as Highcharts.Series | null
                  if (serie && serieId !== id) {
                    serie.setState("inactive", true)
                  }
                })
              }}
              onMouseOut={() => {
                serieIds.forEach((serieId) => {
                  const serie = chartApi?.get(
                    `serie-${serieId}`
                  ) as Highcharts.Series | null
                  if (serie && serieId !== id) {
                    serie.setState(undefined, true)
                  }
                })
              }}
              onClick={() => {
                if (serieIds[idx]) {
                  onVisbilityChange?.(id, !isVisible)

                  if (!onVisbilityChange) {
                    const serie = chartApi?.get(
                      `serie-${serieIds[idx]}`
                    ) as Highcharts.Series | null
                    if (serie) {
                      serie.setVisible(!isVisible, true)
                    }
                  }
                }
              }}
            />
          )
        })}
      </Row>
    </Stack>
  )
}

function Innerlegend({
  serieSettingsAtomAtoms,
  onVisibilityChange,
}: {
  serieSettingsAtomAtoms: Array<Atom<CustomChartSerie>>
} & Omit<LegendProps, "serieSettingsAtom">) {
  return (
    <Row start gap="6x">
      {serieSettingsAtomAtoms.map((chartSettingAtom, index) => {
        return (
          <Fragment key={index}>
            {index > 0 ? <Divider vertical variant="secondary" /> : null}
            <LegendRow
              index={index}
              settingsAtom={chartSettingAtom}
              onVisbilityChange={onVisibilityChange}
            />
          </Fragment>
        )
      })}
    </Row>
  )
}

export const Legend = memo(function Legend({
  serieSettingsAtom,
  ...props
}: LegendProps) {
  const serieSettingsAtomAtoms = useAtomValue(
    useMemo(() => {
      const syncSerieSettings = unwrap(
        serieSettingsAtom,
        () => [] as Array<CustomChartSerie>
      )

      return splitAtom(syncSerieSettings)
    }, [serieSettingsAtom])
  )

  return (
    <Suspense>
      <Innerlegend serieSettingsAtomAtoms={serieSettingsAtomAtoms} {...props} />
    </Suspense>
  )
})
