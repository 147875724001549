import { useEffect, useState } from 'react'
import type {
  StockChart,
  Chart,
  SVGRenderer as SVGRendererType,
  HTMLDOMElement,
  Options,
  ChartCallbackFunction,
} from 'highcharts/highstock'

type ChartCallback = (
  renderTo: string | HTMLDOMElement,
  options: Options,
  callback?: ChartCallbackFunction,
) => Chart
type StockChartCallback = (
  renderTo: string | HTMLDOMElement,
  options: Options,
  callback?: ChartCallbackFunction,
) => StockChart

type Charts = {
  stockChart: StockChartCallback
  chart: ChartCallback
  SVGRenderer: SVGRendererType
}

let highcharts: Promise<Charts>

// Dynamically fetch highcharts as it's used in a useEffect and not need to be part of initial bundle
function getHighcharts(): Promise<Charts> {
  if (!highcharts) {
    highcharts = import(/* webpackPreload: true */ 'highcharts/highstock').then(
      (Highcharts) => {
        return {
          stockChart: Highcharts.stockChart,
          chart: Highcharts.chart,
          SVGRenderer: Highcharts.SVGRenderer,
        } as unknown as Charts
      },
    )
  }

  return highcharts
}

export type { Options }

export const useHighcharts = () => {
  const [highchartsInstance, setHighchartInstance] = useState<Charts>()

  useEffect(() => {
    getHighcharts().then((highchart) => setHighchartInstance(highchart))
  }, [])

  return highchartsInstance
}
