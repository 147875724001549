/*
 * Separated color palette from VE style settings due to limitations of css.ts -files and
 * still having to deal with styled components and whatnot implementations.
 */
export const palette = {
  white: '#ffffff',
  black: '#1B1B1E', // DUPLICATE of Grey10 now. In use for the old theming way
  grey5: '#101014',
  grey10: '#1B1B1E',
  grey13: '#1E1E21', // Special grey used by table on every other row. Made from grey15 @ 15% opacity. However we cant use opacity due to sticky table.
  grey15: '#2D2D32',
  grey30: '#58585B',
  grey50: '#88888A',
  grey70: '#B7B7B9',
  grey85: '#E7E7EB',
  grey90: '#E7E7EB',
  grey93: '#FBFBFC', // Special grey used by table on every other row. Made from grey90 @ 15% opacity. However we cant use opacity due to sticky table.
  grey95: '#F7F7F7',

  turquise40: '#00A67E',
  turquise60: '#00CF9D',
  turquise80: '#6FECCE',
  turquise90: '#E0FFF8',
  orange: '#E95626',
  purple: '#9280FF',
  lightSalmon: '#FF9877',
  favoriteYellow: '#FAD38B',

  /* transparents */
  transparent30: 'rgba(0,0,0, .3)',
  transparent70: 'rgba(0,0,0, .7)',

  shadowLight: 'rgba(0, 0, 0, 0.08)',
  shadowDark: 'rgba(0, 0, 0, 0.2)',
  shadowDarkest: 'rgba(0, 0, 0, 0.4)',

  lightGrey15: '#242428',
  lightGrey50: '#F3F3F3',
}

export const tokens = {
  primary: {
    light: palette.white,
    dark: palette.grey10,
    // TODO/Propsal: this should have highlight, which is highlight background color (turqoise90)
  },
  onPrimary: {
    light: palette.grey10,
    dark: palette.grey95,
    // TODO/Propsal: this should have highlight, which is highlight color (turqoise60)
  },
  secondary: {
    light: palette.grey95,
    dark: palette.grey15,
    // TODO/Propsal: this should have highlight, which is secondary/alternate highlight background color (light purple - not invented)
  },
  onSecondary: {
    light: palette.grey30,
    dark: palette.grey70,
    // TODO/Propsal: this should have highlight, which is secondary/alternate highlight background color (purple)
  },
  contrastBorder: {
    // Not sure why primary border wasn't like this. Maybe TODO to refactor later on when there's a chance
    light: palette.grey70,
    dark: palette.grey30,
  },
  border: {
    light: palette.grey90,
    dark: palette.grey30,
  },
  secondaryBorder: {
    light: palette.grey90,
    dark: palette.grey15,
  },
  body: {
    light: palette.grey95,
    dark: palette.grey5,
  },
  shadow: {
    light: palette.shadowLight,
    dark: palette.shadowDark,
  },
  heavyShadow: {
    light: palette.shadowDark,
    dark: palette.shadowDarkest,
  },
  table: {
    light: palette.grey93,
    dark: palette.grey13,
  },
}
