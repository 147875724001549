import {
  type Options,
  type TooltipFormatterCallbackFunction,
  type TooltipFormatterContextObject,
  type Chart as HighchartsChart,
} from 'highcharts'
import { type RefObject, useContext, useEffect, useRef, useState } from 'react'
import { ChartContext } from './ChartContext'
import { chartCSSVars } from './ChartContract.css'
import { getColors } from './colors'
import { type TooltipOptions, formatTooltip } from './Tooltip'
import { tooltipContainerCss } from './Tooltip.css'
import { useHighcharts } from './useHighcharts'

const NAVIGATOR_HANDLE = function (
  x: number,
  y: number,
  w: number,
  h: number,
  options: { width?: number; height?: number; radius?: number },
) {
  const halfWidth = options.width ? options.width / 2 : w
  const markerPosition = Math.round(halfWidth / 4) + 0.5
  const round = options.radius || 2
  const height = options.height || h

  /*
    How this was done:

    Quote from Sami:
    There really isn't a proper documentation for from highcharts,
    but we used the "symbols" option for handles (https://api.highcharts.com/highstock/navigator.handles.symbols)

    In the docs they give this demo:
    https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/stock/navigator/styled-handles/

    And with some tweaking and help from the Highcharts support team it was possible to build what is seen possible.
  */

  return [
    ['M', -halfWidth - 1 + round, 0.5],
    ['L', halfWidth - round, 0.5],
    ['C', halfWidth, 0.5, halfWidth, 0.5 + round, halfWidth, 0.5 + round],
    ['L', halfWidth, height + 0.5 - round],
    [
      'C',
      halfWidth,
      height + 0.5 - round,
      halfWidth,
      height + 0.5,
      halfWidth - round,
      height + 0.5,
    ],
    ['L', -halfWidth - 1 + round, height + 0.5],
    [
      'C',
      -halfWidth - 1,
      height + 0.5,
      -halfWidth - 1,
      height + 0.5 - round,
      -halfWidth - 1,
      height + 0.5 - round,
    ],
    ['L', -halfWidth - 1, 0.5 + round],
    [
      'C',
      -halfWidth - 1,
      0.5,
      -halfWidth - 1 + round,
      0.5,
      -halfWidth - 1 + round,
      0.5,
    ],
    ['M', -markerPosition, 4],
    ['L', -markerPosition, height - 3],
    ['M', markerPosition - 1, 4],
    ['L', markerPosition - 1, height - 3],
  ]
}

const DEFAULT_OPTIONS: Partial<Options> = {
  chart: {
    animation: false,
    alignTicks: true,
    alignThresholds: true,
    style: {
      fontFamily: chartCSSVars.fontFamily,
      fontWeight: '400',
    },
    backgroundColor: 'transparent',
    spacing: [0, 5, 0, 5] as Array<number>,
    reflow: false,
  },
  colors: getColors(10),
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  legend: {
    align: 'center',
    verticalAlign: 'top',
    squareSymbol: false,
    symbolHeight: 12,
    symbolWidth: 12,
    symbolPadding: 10,
    symbolRadius: 1,
    itemStyle: {
      color: chartCSSVars.legend.color,
      fontWeight: chartCSSVars.legend.fontWeight,
    },
    itemHoverStyle: {
      color: chartCSSVars.legend.color,
    },
    itemHiddenStyle: {
      opacity: 0.666,
    },
  },
  navigator: {
    handles: {
      symbols: ['roundedBox', 'roundedBox'],
      width: 9,
      height: 17,
      backgroundColor: chartCSSVars.navigator.backgroundColor,
      borderColor: chartCSSVars.navigator.borderColor,
    },
    outlineColor: chartCSSVars.navigator.outlineColor,
    outlineWidth: 1,
    xAxis: {
      gridLineColor: chartCSSVars.axis.gridColor,
    },
    maskFill: chartCSSVars.navigator.maskFill,
  },
  plotOptions: {
    area: {
      fillOpacity: 0.3,
      connectEnds: true,
      connectNulls: true,
      zIndex: 5,
      lineWidth: 1.75,
    },
    column: {
      crisp: false,
      dataLabels: {
        padding: 0,
      },
      borderColor: 'unset',
      opacity: 1, // Ensures that eg. Bitcoin with 14y data at max zoom + day granularity doesnt become solid bar
    },
    line: {
      zIndex: 10,
    },
    series: {
      animation: false,
      showInNavigator: true,
      label: {
        style: {
          fontWeight: '500',
        },
      },
    },
  },
  rangeSelector: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  title: {
    text: ``,
    margin: 0,
  },
  tooltip: {
    className: tooltipContainerCss,
    borderColor: 'transparent',
    followPointer: true,
    borderRadius: 10, // Due to svg, border-radius via CSS doesn't seem to do exactly what we want. Even this feels slightly off, especially in top left corner
    shadow: {
      offsetX: 0,
      offsetY: 0,
      color: 'black',
      opacity: 0.00666,
      width: 9,
    },
    formatter(
      tooltip: Parameters<TooltipFormatterCallbackFunction>[0],
    ): ReturnType<TooltipFormatterCallbackFunction> {
      return formatTooltip.call(
        this as unknown as TooltipFormatterContextObject,
        tooltip,
      )
    },
    headerFormat: `{point.key}`,
    outside: false,
    padding: 0,
    shared: true,
    split: false,
    useHTML: true,
    style: {
      zIndex: 9999,
      color: 'inherit',
    },
  },
  exporting: {
    enabled: false,
  },
}

export function useInitializeHighcharts(
  el: RefObject<HTMLDivElement>,
  {
    chartType,
    showNavigator,
    tooltipOptions,
    legend,
  }: {
    chartType: 'chart' | 'stockChart'
    showNavigator: boolean
    tooltipOptions: Partial<TooltipOptions>
    legend: boolean
  },
) {
  const { _events } = useContext(ChartContext)
  const tooltipOptionsRef = useRef(tooltipOptions)
  const highchartsInstance = useHighcharts()
  const chartRef = useRef<HighchartsChart | null>(null)
  const [, setIsMounted] = useState(false)

  tooltipOptionsRef.current = tooltipOptions

  useEffect(() => {
    if (!highchartsInstance || !el.current) {
      return
    }

    const chartOptions: Options = {
      ...DEFAULT_OPTIONS,
      navigator: {
        ...DEFAULT_OPTIONS.navigator,
        enabled: showNavigator && chartType === 'stockChart',
      },
      legend: {
        ...DEFAULT_OPTIONS.legend,
        enabled: legend,
      },
      tooltip: {
        ...DEFAULT_OPTIONS.tooltip,
        formatter(
          tooltip: Parameters<TooltipFormatterCallbackFunction>[0],
        ): ReturnType<TooltipFormatterCallbackFunction> {
          return formatTooltip.call(
            this as unknown as TooltipFormatterContextObject,
            tooltip,
            { sort: false, showTotal: false, ...tooltipOptionsRef.current },
          )
        },
      },
      chart: {
        ...DEFAULT_OPTIONS.chart,
        spacingTop: legend ? undefined : 25,
        events: {
          redraw: function () {
            _events.current?.redraw?.forEach((cb) => cb.call(this))
          },
          load: function () {
            _events.current?.load?.forEach((cb) => cb.call(this))
          },
          addSeries: function (e) {
            _events.current?.addSeries?.forEach((cb) => cb.call(this, [e]))
          },
          render: function () {
            // // We need to count how many yAxisItems we have, I haven't found a great way so this is what it is
            // const yAxisSet = new Set<Axis>()
            // let min = Number.MAX_SAFE_INTEGER
            // let max = 0
            // this.series.forEach((serie) => {
            //   // @ts-ignore navigator is present and we need to check if it's not the navigator graph
            //   if (this.navigator?.series?.includes(serie)) {
            //     return
            //   }

            //   yAxisSet.add(serie.yAxis)

            //   // if all values are zero (possible for example on token incentives)
            //   // we change the type into line so it is visible on bottom of chart
            //   if (
            //     serie.dataMax === 0 &&
            //     serie.dataMin === 0 &&
            //     serie.type !== 'line'
            //   ) {
            //     serie.update({ type: 'line' }, true)
            //   }

            //   // @ts-ignore - it does exists
            //   min = Math.min(serie.dataMin || 0, min)
            //   // @ts-ignore - it does exists
            //   max = Math.max(serie.dataMax || 0, max)
            // })

            // for (const axis of yAxisSet) {
            //   const { dataMin, dataMax } = axis.getExtremes()
            //   let { userMin, userMax }: { userMin?: number; userMax?: number } =
            //     axis.getExtremes()
            //   const { min: axisMin, max: axisMax } = axis.options

            //   if (dataMin === null || dataMax === null) {
            //     return
            //   }

            //   let actualMin = dataMin
            //   if (axisMin) {
            //     actualMin = Math.min(dataMin, axisMin)
            //   }
            //   let actualMax = dataMax
            //   if (axisMax) {
            //     actualMax = Math.min(dataMax, axisMax)
            //   }

            //   debugger
            //   if (min < 0) {
            //     if (typeof userMin === 'undefined' || min < userMin) {
            //       if (
            //         highchartsExtremeCache.current.min !== actualMin ||
            //         highchartsExtremeCache.current.max !== undefined
            //       ) {
            //         highchartsExtremeCache.current.min = actualMin
            //         highchartsExtremeCache.current.max = undefined
            //         axis.setExtremes(actualMin, undefined, true, false)
            //       }
            //     }
            //   } else if (min === 0 && max === 0) {
            //     if (typeof userMax === 'undefined') {
            //       if (
            //         highchartsExtremeCache.current.min !== 0 ||
            //         highchartsExtremeCache.current.max !== 10000
            //       ) {
            //         highchartsExtremeCache.current.min = 0
            //         highchartsExtremeCache.current.max = 10000
            //         axis.setExtremes(0, 10000, true, false)
            //       }
            //     }
            //   } else if (userMin !== dataMin || userMax !== actualMax) {
            //     const value = Math.min(0, actualMax)

            //     if (userMin !== value || userMax !== actualMax) {
            //       if (
            //         highchartsExtremeCache.current.min !== value ||
            //         highchartsExtremeCache.current.max !== actualMax
            //       ) {
            //         highchartsExtremeCache.current.min = value
            //         highchartsExtremeCache.current.max = actualMax
            //         axis.setExtremes(value, actualMax, true, false)
            //       }
            //     } else {
            //       if (value !== userMin) {
            //         if (
            //           highchartsExtremeCache.current.min !== value ||
            //           highchartsExtremeCache.current.max !== actualMax
            //         ) {
            //           highchartsExtremeCache.current.min = value
            //           highchartsExtremeCache.current.max = actualMax
            //           axis.setExtremes(value, actualMax, true, false)
            //         }
            //       }
            //     }
            //   }
            // }

            _events.current?.render?.forEach((cb) => cb.call(this))
          },
        },
      },
    }

    // @ts-ignore - SVGRendere is a special trick - https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/stock/navigator/styled-handles/
    highchartsInstance.SVGRenderer.prototype.symbols.roundedBox =
      NAVIGATOR_HANDLE
    chartRef.current = highchartsInstance[chartType](el.current!, chartOptions)
    chartRef.current.redraw(false)
    setIsMounted(true)

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
      }

      setIsMounted(false)
      chartRef.current = null
    }
  }, [highchartsInstance, chartType, showNavigator, el, legend, _events])

  return chartRef
}
