import { formatDate } from '@tokenterminal/ui/utils'
import { SERIE_FORMAT } from '../ChartOptions'
import type {
  ChartSerie,
  ChartSerieNonTimeData,
  ChartSerieTimeData,
} from '../useHighchartOptions'

function getMetricUnit(format: `${SERIE_FORMAT}`) {
  switch (format) {
    case SERIE_FORMAT.RATIO:
      return 'x'
    case SERIE_FORMAT.PERCENTAGE:
      return '%'
    case SERIE_FORMAT.PRICE:
      return '$'
    case SERIE_FORMAT.NUMBER:
      return '#'
    default:
      return ''
  }
}

function dateToCsvFormat(timestamp: string) {
  return formatDate(new Date(Number.parseInt(timestamp, 10)))
}

function getColumnName(
  { yAxis, label, format }: ChartSerie,
  isTimeBased: boolean = false,
) {
  const unit = getMetricUnit(format)
  const headerName = isTimeBased ? label : yAxis

  if (unit) {
    return `${headerName} (${unit})`
  }

  return headerName
}

function generateTimeSeriesData(
  chartSeries: Array<ChartSerie>,
  chartData: Array<ChartSerieTimeData>,
) {
  const DELIMITER = ','
  const header = 'Date'

  const dataByTimestamp: Record<number, Record<string, number | null>> = {}
  for (let i = 0; i < chartData.length; i++) {
    chartData[i].forEach(([timestamp, value]) => {
      dataByTimestamp[timestamp] = {
        ...dataByTimestamp[timestamp],
        [chartSeries[i].name]: value,
      }
    })
  }

  const csvHeaderRow = `${header},${chartSeries
    .map((serie) => getColumnName(serie, true))
    .join(DELIMITER)}`

  if (!Object.keys(dataByTimestamp).length) {
    return csvHeaderRow
  }

  // Object keys are unsorted thus we need to sort them for timelines
  // We sort only timestamp based data sets
  const rows: Array<string> = Array.from(Object.entries(dataByTimestamp))
    .sort(
      ([prevTimestamp], [nextTimestamp]) =>
        new Date(parseInt(prevTimestamp)).getTime() -
        new Date(parseInt(nextTimestamp)).getTime(),
    )
    .map(
      ([key, rowData]) =>
        `${dateToCsvFormat(key)},${chartSeries
          .map((serie) => rowData[serie.name]?.toFixed(2))
          .join(',')}`,
    )

  return `${csvHeaderRow}\n${rows.join('\n')}`
}

function generateSeriesData(
  chartSeries: Array<ChartSerie>,
  chartData: Array<ChartSerieNonTimeData>,
) {
  const header = 'Project'

  const csvHeaderRow = `${header},${getColumnName(chartSeries[0])}`

  const firstSerieData = chartData[0]

  // Object keys are unsorted thus we need to sort them for timelines
  // We sort only timestamp based data sets
  const rows: Array<string> = firstSerieData.map(
    ([key, rowData]) => `${key},${rowData.toFixed(2)}`,
  )

  return `${csvHeaderRow}\n${rows.join('\n')}`
}

// Highcharts does not support exporting granularity
export function generateData<T extends boolean = boolean>(
  chartSeries: Array<ChartSerie>,
  chartData: Array<ChartSerieNonTimeData> | Array<ChartSerieTimeData>,
  { isTimeBased }: { isTimeBased: T },
): string {
  if (!Object.keys(chartData).length || typeof isTimeBased === 'undefined') {
    return `Data not loaded yet, please try again`
  }

  if (isTimeBased) {
    return generateTimeSeriesData(
      chartSeries,
      chartData as Array<ChartSerieTimeData>,
    )
  } else {
    return generateSeriesData(
      chartSeries,
      chartData as Array<ChartSerieNonTimeData>,
    )
  }
}
