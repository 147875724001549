import { type ChainMetadata } from "@tokenterminal/tt-analytics-api-types/dist/api/chains"
import { type CompositionChartPayload } from "@tokenterminal/tt-analytics-api-types/dist/api/charts"
import {
  type Filter,
  type CustomChartSerie,
} from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type AvailableMarketSectors } from "@tokenterminal/tt-analytics-api-types/dist/api/market-sectors"
import { type MetricConfiguration } from "@tokenterminal/tt-analytics-api-types/dist/api/metrics"
import { type ProjectMeta } from "@tokenterminal/tt-analytics-api-types/dist/api/projects"
import { type ChartSerie } from "@tokenterminal/ui/Chart/Chart"
import {
  type SERIE_STACK,
  SERIE_TYPE,
  type SERIE_FORMAT,
} from "@tokenterminal/ui/Chart/ChartOptions"
import { atom, type Getter, type Atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { SERIE_TYPES } from "../../types"
import { generateId } from "../../utils/generate-id"
import {
  metricsConfigurationsAtom,
  marketSectorsAtom,
  chainsAtom,
  projectsAtom,
  getProjectsByMetricAtom,
} from "./meta-atoms"
import { getMetricCompositionChartDataByMetricAndChainAtom } from "./metric-composition-data-atom"
import { getAggregatedChartDataByMetricAndBlockchainAtom } from "./metrics-blockchain-data-atom"
import { getSerieIdsFromChartSettingAtom } from "./serie-ids-atom"
import { getYaxisMergePaths } from "./yaxis-options-atom"

function getStack(serieType: `${SERIE_TYPES}`, isPercentageShare: boolean) {
  if (
    serieType !== SERIE_TYPES.BAR_STACKED &&
    serieType !== SERIE_TYPES.AREA_STACKED
  ) {
    return undefined
  }

  return isPercentageShare ? "percent" : "normal"
}

function convertChartTypeToHighcharts(
  chartType: `${SERIE_TYPES}`
): `${SERIE_TYPE}` {
  switch (chartType) {
    case SERIE_TYPES.LINE:
      return SERIE_TYPE.LINE
    case SERIE_TYPES.BAR_STACKED:
    case SERIE_TYPES.BAR_UNSTACKED:
      return SERIE_TYPE.COLUMN
    case SERIE_TYPES.AREA_STACKED:
    case SERIE_TYPES.AREA_UNSTACKED:
      return SERIE_TYPE.AREA
    default:
      return SERIE_TYPE.LINE
  }
}

function getMetricApproximation(
  metric: MetricConfiguration | undefined
): "average" | "sum" {
  if (metric?.static) {
    return "average"
  }
  return "sum"
}
function getProjectsFromFilters(filters: Array<Filter>) {
  return Array.from(
    new Set(
      filters.flatMap((filter) => {
        if (filter.type !== "project") {
          return []
        }

        return filter.values
      })
    )
  )
}

type TimeSerieByProjectsArgs<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  K extends keyof T,
  U,
> = {
  metricConfig: MetricConfiguration
  serieType: `${SERIE_TYPE}`
  stack?: `${SERIE_STACK}`
  isCumulative?: boolean
  labelField: K
  metaDictionary: Map<U, T>
  values: Array<U>
}

async function getSeriesConfigurationOptions(
  chartSetting: CustomChartSerie,
  get: Getter
): Promise<{
  values: Array<string>
  dictionary: Map<string, AvailableMarketSectors | ChainMetadata | ProjectMeta>
}> {
  if (chartSetting.filters.length === 1) {
    const values = chartSetting.filters[0]!.values

    if (
      chartSetting.filters[0]!.type === "market_sector" &&
      chartSetting.groupBy === "chain"
    ) {
      return {
        values,
        dictionary: toDictionary(
          unwrapWithData(await get(marketSectorsAtom)) ?? [],
          "id"
        ),
      }
    } else if (
      chartSetting.filters[0]!.type === "market_sector" &&
      chartSetting.groupBy === "project"
    ) {
      const marketSectorFilter = chartSetting.filters[0]!
      const projectsMeta = await get(
        getProjectsByMetricAtom(chartSetting.metric)
      )

      const availableProjects = projectsMeta.filter((project) => {
        return project.flattened_tags.some((tag) =>
          marketSectorFilter.values.includes(tag)
        )
      })

      return {
        values: availableProjects.map((project) => project.data_id),
        dictionary: toDictionary(
          unwrapWithData(await get(projectsAtom)),
          "data_id"
        ),
      }
    } else if (
      chartSetting.filters[0]!.type === "project" &&
      chartSetting.groupBy === "project"
    ) {
      return {
        values,
        dictionary: toDictionary(
          unwrapWithData(await get(projectsAtom)),
          "data_id"
        ),
      }
    }
  }

  const blockchainFilter = chartSetting.filters.find(
    (filter) => filter.type === "blockchain"
  )

  if (blockchainFilter) {
    // eslint-disable-next-line prefer-const
    let [chainsMeta, projectsMeta, aggregatedChartData] = await Promise.all([
      get(chainsAtom),
      get(projectsAtom),
      get(
        getAggregatedChartDataByMetricAndBlockchainAtom({
          metric: chartSetting.metric.replace(/-/g, "_"),
          chainIds: blockchainFilter.values,
          projectIds: getProjectsFromFilters(chartSetting.filters),
          interval: "180d",
        })
      ),
    ])

    if (aggregatedChartData.err) {
      return {
        dictionary: new Map(),
        values: [],
      }
    }

    const projectsMetaDictionary = toDictionary(
      unwrapWithData(projectsMeta),
      "data_id"
    )
    const chainsMetaDictionary = toDictionary(
      unwrapWithData(chainsMeta),
      "chain_id"
    )

    const marketSectorFilter = chartSetting.filters.find(
      (f) => f.type === "market_sector"
    )
    if (
      chartSetting.filters.length === 2 &&
      marketSectorFilter &&
      aggregatedChartData.ok
    ) {
      // clone date to avoid mutation
      // @ts-ignore - types are bad
      aggregatedChartData = JSON.parse(
        JSON.stringify(aggregatedChartData)
      ) as unknown as CompositionChartPayload
      aggregatedChartData.ok!.data = aggregatedChartData.ok!.data.filter(
        (row) => {
          const projectMeta = projectsMetaDictionary.get(row.data_id!)
          if (!projectMeta) {
            return false
          }

          return projectMeta?.flattened_tags?.some((tag) =>
            marketSectorFilter.values.includes(tag)
          )
        }
      )
    }

    if (chartSetting.groupBy === "chain") {
      return {
        dictionary: toDictionary(unwrapWithData(chainsMeta), "chain_id"),
        values: [
          ...new Set(
            unwrapWithData(aggregatedChartData).map((row) => {
              return row.chain_id!
            })
          ),
        ],
      }
    } else {
      // todo move to API
      const labelSum = unwrapWithData(aggregatedChartData).reduce(
        (acc, row) => {
          const key = `${row.chain_id}-${row.data_id}`
          acc[key] = acc[key] || 0
          acc[key] += row.value

          return acc
        },
        {} as Record<string, number>
      )

      const serieInfo = unwrapWithData(aggregatedChartData).reduce(
        (acc, row) => {
          if (
            !projectsMetaDictionary.has(row.data_id!) ||
            !chainsMetaDictionary.has(row.chain_id!)
          ) {
            return acc
          }

          const projectMeta = projectsMetaDictionary.get(row.data_id!)
          const chainMeta = chainsMetaDictionary.get(row.chain_id!)
          const key = `${row.chain_id}-${row.data_id}`
          acc.values.push(key)
          acc.dictionary.set(key, {
            name: `${projectMeta?.name} (${chainMeta?.name})`,
          } as ProjectMeta)

          return acc
        },
        {
          dictionary: new Map<string, ProjectMeta>(),
          values: [] as Array<string>,
        }
      )

      serieInfo.values = Array.from(new Set(serieInfo.values))
      serieInfo.values.sort((a, b) => {
        return (labelSum[b] ?? 0) - (labelSum[a] ?? 0)
      })

      return serieInfo
    }
  }

  if (chartSetting.groupBy === "chain") {
    const [chainsMeta, projectsMeta, chainsCompositionPayload] =
      await Promise.all([
        get(chainsAtom),
        get(projectsAtom),
        get(
          getMetricCompositionChartDataByMetricAndChainAtom({
            composeBy: "chain",
            metric: chartSetting.metric,
            projects: getProjectsFromFilters(chartSetting.filters),
            interval: "24h",
          })
        ),
      ])

    if (chainsCompositionPayload.err) {
      return {
        dictionary: new Map(),
        values: [],
      }
    }

    const projectsMetaDictionary = toDictionary(
      unwrapWithData(projectsMeta),
      "data_id"
    )
    const chainsMetaDictionary = toDictionary(
      unwrapWithData(chainsMeta),
      "chain_id"
    )

    return unwrapWithData(chainsCompositionPayload).reduce(
      (acc, row) => {
        const projectMeta = projectsMetaDictionary.get(row.data_id)
        const chainMeta = chainsMetaDictionary.get(row.composition_value)

        const key = `${row.data_id}-${row.composition_value}`
        acc.values.push(key)
        acc.dictionary.set(key, {
          name: `${projectMeta?.name} (${chainMeta?.name})`,
        } as ProjectMeta)

        return acc
      },
      {
        dictionary: new Map<string, ProjectMeta>(),
        values: [] as Array<string>,
      }
    )
  }

  return {
    dictionary: toDictionary(
      unwrapWithData(await get(projectsAtom)),
      "data_id"
    ),
    values: getProjectsFromFilters(chartSetting.filters),
  }
}

export function createTimeSerieByX<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  K extends keyof T,
  U,
>({
  metricConfig,
  serieType,
  metaDictionary,
  values,
  labelField,
  isCumulative,
  stack,
}: TimeSerieByProjectsArgs<T, K, U>): Array<ChartSerie> {
  return values.map((value, index) => {
    const dictionaryValue = metaDictionary.get(value)

    return {
      index,
      yAxis: metricConfig.title,
      name: dictionaryValue![labelField],
      label: `${dictionaryValue![labelField]} - ${metricConfig.title}`,
      type: serieType,
      format: metricConfig.format as SERIE_FORMAT,
      groupingApproximation: getMetricApproximation(metricConfig),
      cumulative: isCumulative,
      stack,
    }
  })
}

export const getSeriesFromChartSettingsAtom = atomFamily(
  function getSeriesFromChartSettingsAtom(
    chartSettingsAtom: Atom<Promisable<Array<CustomChartSerie>>>
  ) {
    const seriesFromChartSettingsAtom = atom(async (get) => {
      const chartSettings = await get(chartSettingsAtom)
      const metricConfigurations = toDictionary(
        unwrapWithData(await get(metricsConfigurationsAtom)) ?? [],
        "slug"
      )

      const yAxisMap = getYaxisMergePaths(chartSettings, metricConfigurations)

      const series: Array<ChartSerie> = []
      await Promise.all(
        chartSettings.map(async (chartSetting) => {
          if (chartSetting) {
            const serieType = chartSetting.chart_type
            const highchartSerieType = convertChartTypeToHighcharts(serieType)
            const config = metricConfigurations.get(chartSetting.metric)

            const isAggregated = !chartSetting.groupBy
            const isCumulative = chartSetting.display === "cumulative"
            const isPercentage = chartSetting.display === "percentage"

            if (!config) {
              return
            }

            if (isAggregated) {
              series.push({
                index: series.length,
                yAxis: yAxisMap.get(chartSetting.id) ?? chartSetting.id,
                name: generateId(chartSetting.id, "aggregated"),
                label: chartSetting.title,
                type: highchartSerieType,
                format: config.format as `${SERIE_FORMAT}`,
                groupingApproximation: getMetricApproximation(config),
                cumulative: isCumulative,
                stack: getStack(serieType, isPercentage),
                color: chartSetting.colors ? chartSetting.colors[0] : undefined,
                visible:
                  chartSetting.visible && Array.isArray(chartSetting.visible)
                    ? chartSetting.visible.includes(
                        generateId(chartSetting.id, "aggregated")
                      )
                    : true,
              })
            } else {
              const { dictionary, values } =
                await getSeriesConfigurationOptions(chartSetting, get)

              const seriesIds = await get(
                getSerieIdsFromChartSettingAtom(atom(chartSetting))
              )

              const baseIndex = series.length
              const newSeries = createTimeSerieByX({
                values,
                labelField: "name",
                metricConfig: config,
                metaDictionary: dictionary,
                serieType: highchartSerieType,
                stack: getStack(serieType, isPercentage),
                isCumulative: isCumulative,
              }).map((serie, index) => {
                const value = values[index]!
                serie.yAxis = yAxisMap.get(chartSetting.id) ?? chartSetting.id
                serie.name = generateId(chartSetting.id, value)
                serie.index = (serie.index ?? 0) + baseIndex

                if (chartSetting.colors?.[index]) {
                  serie.color = chartSetting.colors[index]
                }

                serie.visible =
                  chartSetting.visible && Array.isArray(chartSetting.visible)
                    ? chartSetting.visible.includes(
                        generateId(chartSetting.id, value)
                      )
                    : true

                return serie
              })

              series.push(
                ...newSeries.toSorted((a, b) => {
                  return seriesIds.indexOf(a.name) - seriesIds.indexOf(b.name)
                })
              )
            }
          }
        })
      )

      return series
    })

    return seriesFromChartSettingsAtom
  }
)
