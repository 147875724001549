import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import { ZOOM_ENUM, type ZoomValues } from "../types"

export function convertZoomToAPI(zoom: ZoomValues): `${Interval}` {
  switch (String(zoom)) {
    case "1":
      return "24h"
    case "7":
      return "7d"
    case "30":
      return "30d"
    case "90":
      return "90d"
    case "180":
      return "180d"
    case "365":
      return "365d"
    case `${ZOOM_ENUM.MAX}`:
      return "max"
    case `${ZOOM_ENUM.YTD}`:
      return "ytd"
    case `${ZOOM_ENUM.FOUR_FULL_QUARTERS}`:
      return "4fullq"
  }

  throw new Error(`Unknown zoom value: ${zoom}`)
}

export function convertApiToZoom(zoom: `${Interval}`): ZoomValues {
  switch (zoom) {
    case "24h":
      return 1
    case "7d":
      return 7
    case "30d":
      return 30
    case "90d":
      return 90
    case "180d":
      return 180
    case "365d":
      return 365
    case `max`:
      return ZOOM_ENUM.MAX
    case `ytd`:
      return ZOOM_ENUM.YTD
    case `4fullq`:
      return ZOOM_ENUM.FOUR_FULL_QUARTERS
  }

  throw new Error(`Unknown zoom value: ${zoom}`)
}
