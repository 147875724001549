import { type RefObject, useEffect } from 'react'

function hasResizeObserver() {
  return typeof window.ResizeObserver !== 'undefined'
}

type useResizeObserverOptionsType<T> = {
  ref: RefObject<T | undefined> | undefined
  onResize: (e?: ResizeObserverEntry) => void
}

export function useResizeObserver<T extends Element>(
  options: useResizeObserverOptionsType<T>,
) {
  const { ref, onResize } = options

  useEffect(() => {
    const element = ref?.current
    if (!element) {
      return
    }

    if (!hasResizeObserver()) {
      const handler = () => {
        onResize()
      }
      window.addEventListener('resize', handler, false)
      return () => {
        window.removeEventListener('resize', handler, false)
      }
    } else {
      const resizeObserverInstance = new window.ResizeObserver((entries) => {
        if (!entries.length) {
          return
        }

        onResize(entries[0])
      })
      resizeObserverInstance.observe(element)

      return () => {
        if (element) {
          resizeObserverInstance.unobserve(element)
        }
      }
    }
  }, [onResize, ref])
}
