export const breakpointNames = [
  'mobile',
  'tablet',
  'desktop',
  'wideDesktop',
] as const

export const breakpoints = {
  mobile: 0,
  tablet: 720,
  desktop: 1200,
  wideDesktop: 1440,
} as const

export type Breakpoint = keyof typeof breakpoints
