import {
  type GranularityInfo,
  type GranularityType,
} from './groupDataBasedOnGranularity'

export function calculateGranularityUnit(
  granularity: GranularityType,
): GranularityInfo['unit'] {
  switch (granularity) {
    case 'quarter': {
      return {
        name: 'month',
        count: 3,
      }
    }
    default:
      return {
        name: granularity,
        count: 1,
      }
  }
}
