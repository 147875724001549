export const bodyFontSize = {
  xs: 12,
  s: 14,
  m: 16,
} as const

export const bodyLineHeight = {
  xs: 1.33,
  s: 1.3,
  m: 1.5,
} as const

export const headlineFontSize = {
  s: '1.25em',
  m: '1.625em',
  l: '2em',
  xl: '2.5em',
  xxl: '4em',
} as const

// TODO: fix other line heights
export const headlineLineHeight = {
  s: 1.3,
  m: 1.2,
  l: 1.2,
  xl: 1.2,
  xxl: 1.15,
} as const

export const headlineTabletFontSize = {
  s: '1.25em',
  m: '1.625em',
  l: '2em',
  xl: '2.5em',
  xxl: '4em',
} as const

export const headlineTabletLineHeight = {
  s: 1.2,
  m: 1.2,
  l: 1.2,
  xl: 1.2,
  xxl: 1.2,
} as const
