import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { Row } from "@tokenterminal/ui/Row"
import { TextWithCapsize as Text } from "@tokenterminal/ui/Text"
import { Symbol } from "./Symbol"

export type LegendItemProps = {
  serieType: CustomChartSerie["chart_type"]
  color: string
  name: string
  isActive: boolean
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
}

export function LegendItem({
  serieType,
  color,
  name,
  onClick,
  onMouseOut,
  onMouseOver,
  isActive,
}: LegendItemProps) {
  return (
    <Row
      gap="xs"
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      cursor={onClick ? "pointer" : undefined}
      opacity={isActive ? 1 : 0.5}
    >
      <Symbol serieType={serieType} color={color} />
      <Text variant="primary">{name}</Text>
    </Row>
  )
}
