import { type Filter } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"

export function getProjectsFromFilters(filters: Array<Filter>) {
  return Array.from(
    new Set(
      filters.flatMap((filter) => {
        if (filter.type !== "project") {
          return []
        }

        return filter.values
      })
    )
  )
}
