import { type CompositionChartPayload } from "@tokenterminal/tt-analytics-api-types/dist/api/charts"
import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { atom } from "jotai"
import { type Atom } from "jotai/ts3.8/vanilla"
import { atomFamily } from "jotai/utils"
import { isDefined } from "../../../../utils/isDefined"
import { type Result, unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { getProjectsFromFilters } from "../../utils/get-projects-from-filter"
import {
  chainsAtom,
  getProjectsByMetricAtom,
  marketSectorsAtom,
  projectsAtom,
} from "./meta-atoms"
import { getMetricCompositionChartDataByMetricAndChainAtom } from "./metric-composition-data-atom"
import { getAggregatedChartDataByMetricAndBlockchainAtom } from "./metrics-blockchain-data-atom"

export const getLabelsFromChartSettingAtom = atomFamily(
  (chartSettingAtom: Atom<Promisable<CustomChartSerie>>) => {
    const labelsFromChartSettingAtom = atom<Promise<Array<string>>>(
      async (get) => {
        const chartSetting = await get(chartSettingAtom)

        if (!chartSetting.metric) {
          return [] satisfies Array<string>
        }

        if (!chartSetting.groupBy) {
          return ["Aggregated"]
        }

        const blockchainFilter = chartSetting.filters.find(
          (filter) => filter.type === "blockchain"
        )
        const marketSectorFilter = chartSetting.filters.find(
          (filter) => filter.type === "market_sector"
        )

        if (marketSectorFilter && chartSetting.filters.length === 1) {
          if (chartSetting.groupBy === "project") {
            const projectsMeta = await get(
              getProjectsByMetricAtom(chartSetting.metric)
            )
            const availableProjects = projectsMeta.filter((project) => {
              return project.flattened_tags.some((tag) =>
                marketSectorFilter.values.includes(tag)
              )
            })

            const projects = toDictionary(projectsMeta, "data_id")

            return availableProjects
              .map((project) => projects.get(project.data_id)?.name)
              .filter(isDefined)
          } else {
            const marketSectors = toDictionary(
              unwrapWithData(await get(marketSectorsAtom)),
              "id"
            )
            return chartSetting.filters[0]!.values.map((sector) => {
              return marketSectors.get(sector)?.name || ""
            })
          }
        }

        if (blockchainFilter || chartSetting.groupBy === "chain") {
          const [projectsMeta, chainsMeta] = await Promise.all([
            get(projectsAtom),
            get(chainsAtom),
          ])
          const projectsDictionary = toDictionary(
            unwrapWithData(projectsMeta),
            "data_id"
          )
          const chainsDictionary = toDictionary(
            unwrapWithData(chainsMeta),
            "chain_id"
          )

          if (blockchainFilter) {
            let payload = await get(
              getAggregatedChartDataByMetricAndBlockchainAtom({
                metric: chartSetting.metric.replace(/-/g, "_"),
                chainIds: blockchainFilter.values,
                projectIds: getProjectsFromFilters(chartSetting.filters),
                interval: "max",
              })
            )

            if (payload.err) {
              return []
            }

            if (
              chartSetting.filters.length === 2 &&
              marketSectorFilter &&
              payload.ok
            ) {
              const projectMeta = await get(projectsAtom)
              const projectsMetaDictionary = toDictionary(
                unwrapWithData(projectMeta),
                "data_id"
              )
              // clone date to avoid mutation
              // @ts-ignore - types are bad
              payload = JSON.parse(
                JSON.stringify(payload)
              ) as unknown as Result<CompositionChartPayload, null>
              payload.ok!.data = payload.ok!.data.filter((row) => {
                const projectMeta = projectsMetaDictionary.get(row.data_id!)
                if (!projectMeta) {
                  return false
                }

                return projectMeta?.flattened_tags?.some((tag) =>
                  marketSectorFilter.values.includes(tag)
                )
              })
            }

            if (chartSetting.groupBy === "chain") {
              return [
                ...new Set(
                  payload
                    .ok!.data.map((row) => {
                      if (!chainsDictionary.has(row.chain_id!)) {
                        return undefined
                      }

                      return chainsDictionary.get(row.chain_id!)!.name
                    })
                    .filter(isDefined)
                ),
              ]
            }

            // todo move to API
            const labelSum = payload.ok!.data.reduce(
              (acc, row) => {
                if (
                  !projectsDictionary.has(row.data_id!) ||
                  !chainsDictionary.has(row.chain_id!)
                ) {
                  return acc
                }

                const key = `${projectsDictionary.get(row.data_id!)?.name} (${chainsDictionary.get(row.chain_id!)?.name})`
                acc[key] = acc[key] || 0
                acc[key] += row.value

                return acc
              },
              {} as Record<string, number>
            )

            const labels = Array.from(
              new Set(
                payload
                  .ok!.data.map((row) => {
                    if (
                      !projectsDictionary.has(row.data_id!) ||
                      !chainsDictionary.has(row.chain_id!)
                    ) {
                      return undefined
                    }

                    return `${projectsDictionary.get(row.data_id!)!.name} (${chainsDictionary.get(row.chain_id!)?.name})`
                  })
                  .filter(isDefined)
              )
            )

            return labels.sort((a, b) => {
              return (labelSum[b] ?? 0) - (labelSum[a] ?? 0)
            })
          } else {
            const chainsCompositionPayload = await get(
              getMetricCompositionChartDataByMetricAndChainAtom({
                composeBy: "chain",
                metric: chartSetting.metric,
                projects: getProjectsFromFilters(chartSetting.filters),
                interval: "24h",
              })
            )
            const chains = unwrapWithData(chainsCompositionPayload)
            return Array.from(
              new Set(
                chains
                  .map((chain) => {
                    if (
                      chartSetting.groupBy === "chain" ||
                      blockchainFilter!.values.includes(chain.composition_value)
                    ) {
                      return `${projectsDictionary.get(chain.data_id)!.name} (${chainsDictionary.get(chain.composition_value)?.name})`
                    }
                  })
                  .filter(isDefined)
              )
            )
          }
        }

        if (chartSetting.groupBy === "project") {
          const projects = toDictionary(
            unwrapWithData(await get(projectsAtom)),
            "data_id"
          )

          return getProjectsFromFilters(chartSetting.filters)
            .map((project) => projects.get(project)?.name)
            .filter(isDefined)
        }

        return [] satisfies Array<string>
      }
    )

    if (process.env.NODE_ENV === "development") {
      labelsFromChartSettingAtom.debugLabel = `labelsFromChartSettingAtom(${chartSettingAtom.debugLabel ?? ""})`
    }

    return labelsFromChartSettingAtom
  }
)
