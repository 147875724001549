import { COLORS_LARGE_SET } from "@tokenterminal/ui/Chart/colors"

export const availableColors = COLORS_LARGE_SET
export function getAvailableColor(index: number) {
  if (index >= availableColors.length) {
    return availableColors[availableColors.length - 1]
  }

  return availableColors[index]
}
