import { Box } from "@tokenterminal/ui/Box"
import { Row } from "@tokenterminal/ui/Row"

export function LegendPlaceholder() {
  return (
    <Row start gap="6x">
      <Box style={{ width: 100, height: 40 }} />
    </Row>
  )
}
