// Palette defined in RGB for possibility to use RGBA via css variables. HEX colors for clarity/readability
export const paletteV3 = {
  black: '#030304',
  grey5: '#0E0E0F',
  grey10: '#1B1B1E',
  grey15: '#2D2D32',
  grey30: '#58585B',
  grey50: '#88888A',
  grey70: '#B7B7B9',
  grey80: '#CECECE',
  grey90: '#E7E7EB',
  white: '#FFFFFF', // Non-theme color

  green: '#00CF9D',
  turquise60: '#00CF9D',
  turquise80: '#6FECCE',
  purple: '#9280FF',
  orange: '#E95626',
  lightSalmon: '#FF9877', // Non-theme color

  shadowElevated: 'rgba(0, 0, 0, 0.25)',
  transparentOverlay: 'rgba(45,45,50,.4)',
  transparentBorder: 'rgba(45,45,50,.8)',

  /* transparents */
  // transparent30: 'rgba(0,0,0, .3)',
  // transparent70: 'rgba(0,0,0, .7)',

  // shadowLight: 'rgba(0, 0, 0, 0.08)',
  // shadowDark: 'rgba(0, 0, 0, 0.2)',
  // shadowDarkest: 'rgba(0, 0, 0, 0.4)',

  background: '#030303',
  border: '#1a1a1a',
  card: '#0d0d0d',
  foreground: '#fcfcfc',
  muted: '#aaa',
  brandPrimary: 'rgba(26, 255, 172, 1)',
  brandPrimary40: 'rgba(26, 255, 172, 0.4)',
  brandPrimary20: 'rgba(26, 255, 172, 0.2)',
  brandPrimary10: 'rgba(26, 255, 172, 0.1)',
  brandSecondary: 'rgba(255, 94, 26, 1)',
  brandSecondary40: 'rgba(255, 94, 26, 0.4)',
  brandSecondary20: 'rgba(255, 94, 26, 0.2)',
  brandSecondary10: 'rgba(255, 94, 26, 0.1)',
  active: '#3C3C3C',
  surface: '#262626',
  surfaceBorder: '#404040',
}

// Thank you internet https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
type Leaves<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never
        ? ''
        : `.${Leaves<T[K]>}`}`
    }[keyof T]
  : never

export const getPaletteColor = (
  colorKey: Leaves<typeof paletteV3>,
  alpha = 1,
) => {
  const color = paletteV3[colorKey]
  if (!color) {
    if (process.env.NODE_ENV === 'development') {
      throw 'Color not found'
    }
    return ''
  }

  return alpha === 1 ? `rgb(${color})` : `rgba(${color}, ${alpha})`
}
