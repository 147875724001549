function toRem(pxValue: number): `${number}rem` {
  return `${pxValue / 16}rem`
}

export const space = {
  '0': 0,
  none: 0,
  xxxs: '.125rem',
  xxs: '.25rem',
  xs: '.5rem',
  sm: '.75rem',
  s: '1rem', // 16px
  m: '1.5rem',
  l: '2rem',
  xl: '2.5rem',
  xxl: '5rem',
  fluidXL: 'max(2.5rem, 6.5vw)',
  '0.5x': toRem(2),
  '1x': toRem(4),
  '1.5x': toRem(6),
  '2x': toRem(8),
  '2.5x': toRem(10),
  '3x': toRem(12),
  '3.5x': toRem(14),
  '4x': toRem(16),
  '4.5x': toRem(18),
  '5x': toRem(20),
  '5.5x': toRem(22),
  '6x': toRem(24),
  '6.5x': toRem(26),
  '7x': toRem(28),
  '7.5x': toRem(30),
  '8x': toRem(32),
  '8.5x': toRem(34),
  '9x': toRem(36),
  '9.5x': toRem(38),
  '10x': toRem(40),
  '10.5x': toRem(42),
  '11x': toRem(44),
  '11.5x': toRem(46),
  '12x': toRem(48),
  '12.5x': toRem(50),
  '13x': toRem(52),
  '13.5x': toRem(54),
  '14x': toRem(56),
  '14.5x': toRem(58),
  '15x': toRem(60),
  '15.5x': toRem(62),
  '16x': toRem(64),
} as const

export type Space = keyof typeof space
