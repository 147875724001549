/**
 * A utility to unwrap the result to a throwable state. It also adds the ability to get the data immediately.
 */

export type Result<Value, Error> =
  | { ok: Value; err: null }
  | { ok: null; err: Error }

export function unwrap<Value, Error>(
  result: Result<Value, Error>
): Value extends null ? (Error extends null ? null : never) : Value {
  if (result.err) {
    throw result.err
  }

  return result.ok as Value extends null
    ? Error extends null
      ? null
      : never
    : Value
}

export function unwrapWithData<Value, Error>(
  result: Result<
    {
      data: Value
    },
    Error
  >
): Value extends null ? (Error extends null ? null : never) : Value {
  const resp = unwrap(result) ?? {
    data: null,
  }

  return resp.data as Value extends null
    ? Error extends null
      ? null
      : never
    : Value
}
