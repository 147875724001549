import { type MetricConfiguration } from "@tokenterminal/tt-analytics-api-types/dist/api/metrics"

export function getMetricApproximation(
  metric: Pick<MetricConfiguration, "static"> | undefined
): "average" | "sum" {
  if (metric?.static) {
    return "average"
  }
  return "sum"
}
