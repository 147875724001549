import numeral from 'numeral'
import { formatTime } from '../../utils/date'
import { canUseNumeral } from '../../utils/numerals'
import { overflowTextCss, textXsCss } from '../Text/Text.css'
import { SERIE_FORMAT } from './ChartOptions'
import {
  bytesFormatter as sharedBytesFormatter,
  type FormatterOptions,
  type Formatter,
} from './sharedFormatter'
import {
  tooltipItemColorCss,
  tooltipItemFieldCss,
  tooltipItemValueCss,
  tooltipTildeCss,
} from './Tooltip.css'
import type { FormatterCallbackFunction, Point } from 'highcharts'

function wrapWithSeriesTitleHtml(
  serie: string,
  color?: Point['color']
): string {
  return `<div class="${tooltipItemFieldCss}">${
    color
      ? `<span class="${tooltipItemColorCss}" style="background: ${color}"></span>`
      : ''
  }<span class="${textXsCss}"><span class="${overflowTextCss}">${serie}</span></span></div>`
}

export function wrapWithValuesHtml(values: string[]): string {
  return `<div class="${tooltipItemValueCss} ${textXsCss}">${values.join(
    ' '
  )}</div>`
}

const pricingFormatter: Formatter = function pricingFormatter() {
  return function () {
    const value = this.y || 0
    let finalValue

    const rounded = isNaN(parseFloat(numeral(value).format('0.0a')))
      ? '0'
      : numeral(value).format('0.000a')

    if (canUseNumeral(value)) {
      finalValue =
        Math.abs(value) < 1000
          ? numeral(value).format('0,0.00[00]')
          : numeral(value).format('0,0.00')
    } else {
      finalValue = value
    }
    return `${wrapWithSeriesTitleHtml(
      this.series.name,
      this.color
    )}${wrapWithValuesHtml([
      `<span class="${tooltipTildeCss}"></span> $${rounded}`,
    ])}`
  }
}

const ratioFormatter: Formatter = function ratioFormatter() {
  return function () {
    return `${wrapWithSeriesTitleHtml(
      this.series.name,
      this.color
    )}${wrapWithValuesHtml([`${numeral(this.y).format('0,0.00')}x`])}`
  }
}

const numberFormatter: Formatter = function numberFormatter(options) {
  return function () {
    const value = this.y || 0
    const rounded = isNaN(parseFloat(numeral(value).format('0.0a')))
      ? 0
      : numeral(value).format('0.0a')

    return `${wrapWithSeriesTitleHtml(
      this.series.name,
      this.color
    )}${wrapWithValuesHtml([
      // More precise formatting matches that of getLabelForNumber
      value < 1000
        ? numeral(value).format(Math.abs(value) > 100 ? '0,0' : '0[.]00a')
        : '',
      value > 1000
        ? `${rounded}${options.postfix ? ' ' : ''}`
        : '' + (options.postfix ?? ''),
    ])}`
  }
}

const tokenFormatter: Formatter = function tokenFormatter(options) {
  return function () {
    const value = this.y

    const rounded = isNaN(parseFloat(numeral(value).format('0.0a')))
      ? 0
      : numeral(value).format('0.0a')

    return `${wrapWithSeriesTitleHtml(
      this.series.name,
      this.color
    )}${wrapWithValuesHtml([`${rounded} ${options.token ?? ''}`])}`
  }
}

const percentageFormatter: Formatter = function percentageFormatter(options) {
  return function () {
    /*
     * Value of percent data is either percentage which comes from Show as % share (inbuilt via stack)
     * or from the point value (in case serie is percentage value serie)
     */
    let value: number | undefined
    // If is bar chart, abide to percentage value if known
    /*
     * Lets try out this logic. The aim here is to allow BarCharts to use "% share" via this.percentage
     * while supporting SERIE_FORMAT.PERCENTAGE too.
     * For non BarChart we only support serie value, because % share probably never makes sense in those cases.
     *
     * TODO: Make this less of a hack. We could dig into context, but probably not in UI level, so that means prop, which means re-rendering possibly. Maybe should be an arg.
     */
    if (options.isPercentageShare) {
      value = this.percentage ?? this.y
    } else {
      value = (this.y || 0) * 100
    }

    return `${wrapWithSeriesTitleHtml(
      this.series.name,
      this.color
    )}${wrapWithValuesHtml([`${numeral(value).format('0,0.00')}%`])}`
  }
}

function cumulativeFormatter(formatter: (this: Point) => string) {
  return function (this: any) {
    const idx = this.index
    const value = this.series.processedYData
      .slice(0, idx + 1)
      .reduce((partialSum: number, a: number) => partialSum + a, 0)

    return formatter.call({
      ...this,
      y: value,
    })
  }
}

const timeFormatter: Formatter = function timeFormatter() {
  return function () {
    const value = this.y

    return `${wrapWithSeriesTitleHtml(
      this.series.name,
      this.color
    )}${wrapWithValuesHtml([
      typeof value === 'number' ? formatTime(value) : '-',
    ])}`
  }
}

const bytesFormatter: Formatter = function timeFormatter() {
  return function () {
    const value = this.y

    return `${wrapWithSeriesTitleHtml(
      this.series.name,
      this.color
    )}${wrapWithValuesHtml([
      typeof value === 'number' ? sharedBytesFormatter(value) : '-',
    ])}`
  }
}

const formatters = {
  [SERIE_FORMAT.PRICE]: pricingFormatter,
  [SERIE_FORMAT.RATIO]: ratioFormatter,
  [SERIE_FORMAT.PERCENTAGE]: percentageFormatter,
  [SERIE_FORMAT.NUMBER]: numberFormatter,
  [SERIE_FORMAT.TOKEN]: tokenFormatter,
  [SERIE_FORMAT.TIME]: timeFormatter,
  [SERIE_FORMAT.BYTES]: bytesFormatter,
  [SERIE_FORMAT.TOKEN_ICP]: function (options: FormatterOptions) {
    return tokenFormatter({
      ...options,
      token: 'ICP',
    })
  },
  [SERIE_FORMAT.TOKEN_CKBTC]: function (options: FormatterOptions) {
    return tokenFormatter({
      ...options,
      token: 'ckBTC',
    })
  },
  [SERIE_FORMAT.CYCLES_PER_SECONDS]: function (options: FormatterOptions) {
    return numberFormatter({
      ...options,
      postfix: 'cycles/second',
    })
  },
} as const

export function getFormatter(
  serie: `${SERIE_FORMAT}`,
  {
    cumulative = false,
    ...options
  }: FormatterOptions & {
    cumulative?: boolean
  }
): FormatterCallbackFunction<Highcharts.Point> {
  if (cumulative) {
    return cumulativeFormatter(formatters[serie](options))
  }

  return formatters[serie](options)
}
