import { atom, type Atom } from "jotai"

export function focusAtom<
  Value,
  Prop extends keyof Value,
  R extends Promisable<Value>,
>(
  baseAtom: Atom<Promisable<Value>>,
  propertyName: Prop
): Atom<R extends Promise<Value> ? Promise<Value[Prop]> : Value[Prop]> {
  const focusedAtom = atom((get) => {
    const baseValue = get(baseAtom)

    return baseValue instanceof Promise
      ? (baseValue as Promise<Value>).then((value) => value[propertyName])
      : (baseValue as unknown as Value)[propertyName]
  })

  focusedAtom.debugLabel = `focusAtom(${baseAtom.debugLabel}-${propertyName as string})`
  return focusedAtom as Atom<
    R extends Promise<Value> ? Promise<Value[Prop]> : Value[Prop]
  >
}
