export enum ZOOM_ENUM {
  MAX = 0,
  YTD = -1,
  FOUR_FULL_QUARTERS = -2,
}

export type ZoomValues =
  | ZOOM_ENUM.YTD
  | ZOOM_ENUM.MAX
  | ZOOM_ENUM.FOUR_FULL_QUARTERS
  | 1
  | 7
  | 30
  | 90
  | 180
  | 365

export enum SERIE_TYPES {
  LINE = "line",
  BAR_STACKED = "bar-stacked",
  BAR_UNSTACKED = "bar-unstacked",
  AREA_STACKED = "area-stacked",
  AREA_UNSTACKED = "area-unstacked",
  NUMBER = "number",
}
