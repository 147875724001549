import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import {
  type ChartProps as UIChartProps,
  Chart as UIChart,
  type GranularityType,
} from "@tokenterminal/ui/Chart/Chart"
import { useAtomValue, type Atom } from "jotai"
import { unwrap } from "jotai/utils"
import { useMemo } from "react"
import { useSeriesData } from "../../hooks/useSeriesData"
import { useTooltipOptions } from "../../hooks/useTooltipOptions"
import { useYAxisOptions } from "../../hooks/useYAxisOptions"
import { getSeriesFromChartSettingsAtom } from "../../store/chart/series-atom"
import { convertApiToZoom } from "../../utils/convert-zoom-to-api"

type BaseChartProps = {
  chartSerieSettingsAtom: Atom<Promisable<Array<CustomChartSerie>>>
  zoomAtom: Atom<Promisable<Interval>>
  granularityAtom: Atom<Promisable<GranularityType>>
  showCurrentPeriodAtom: Atom<Promisable<boolean>>
  isVisible?: boolean
}

export function BaseChart({
  chartSerieSettingsAtom,
  zoomAtom,
  granularityAtom,
  showCurrentPeriodAtom,
  isVisible = true,
}: BaseChartProps) {
  const zoom = useAtomValue(zoomAtom)
  const granularity = useAtomValue(granularityAtom)
  const showCurrentPeriod = useAtomValue(showCurrentPeriodAtom)

  const series = useAtomValue(
    useMemo(
      () =>
        unwrap(
          getSeriesFromChartSettingsAtom(chartSerieSettingsAtom),
          (prev) => {
            return prev || []
          }
        ),
      [chartSerieSettingsAtom]
    )
  )

  const seriesData = useSeriesData(chartSerieSettingsAtom, {
    zoom,
  })
  const yAxisOptions: UIChartProps["yAxisOptions"] = useYAxisOptions(
    chartSerieSettingsAtom
  )
  const tooltipOptions = useTooltipOptions(chartSerieSettingsAtom)

  return isVisible ? (
    <UIChart
      series={series}
      seriesData={seriesData}
      zoom={convertApiToZoom(zoom)}
      granularity={granularity}
      yAxisOptions={yAxisOptions}
      showNavigator={false}
      isTimeBased
      tooltipOptions={tooltipOptions}
      legend={false}
      showCurrentPeriod={showCurrentPeriod}
    />
  ) : null
}
