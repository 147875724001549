import { type Chart as HighchartChart } from 'highcharts/highstock'
import {
  type RefObject,
  createContext,
  useState,
  useRef,
  useMemo,
  type FunctionComponent,
  type JSX,
  useCallback,
  type ReactNode,
} from 'react'
import {
  type ChartSerie,
  type ChartSerieNonTimeData,
  type ChartSerieTimeData,
} from './useHighchartOptions'
import { generateData } from './utils/getCSVData'

type AvailableEvents = 'addSeries' | 'render' | 'redraw' | 'load'

type ChartContextType = {
  _events: RefObject<Record<AvailableEvents, Array<Function>>>
  chartApi: HighchartChart | null
  events: {
    add: (type: AvailableEvents, callback: Function) => void
    remove: (type: AvailableEvents, callback: Function) => void
  }
  setApi: (api: HighchartChart) => void
  setChartData: (
    chartSeries: Array<ChartSerie>,
    chartData: Array<ChartSerieNonTimeData> | Array<ChartSerieTimeData>,
    isTimeBased: boolean,
  ) => void
  getChartCSV: () => string | null
}

const DEFAULT_CONTEXT_VALUES: ChartContextType = {
  _events: {
    current: {},
  } as RefObject<Record<AvailableEvents, Array<Function>>>,
  chartApi: null,
  setApi: () => {},
  getChartCSV: () => {
    return null
  },
  setChartData: () => {},
  events: {
    add: () => {},
    remove: () => {},
  },
}
export const ChartContext = createContext<ChartContextType>(
  DEFAULT_CONTEXT_VALUES,
)

export function ChartContextProvider({ children }: { children: ReactNode }) {
  const _events = useRef<Record<AvailableEvents, Array<Function>>>({
    addSeries: [],
    load: [],
    render: [],
    redraw: [],
  })
  const [api, setApi] = useState<HighchartChart | null>(null)
  const [chartData, setChartData] = useState<{
    chartSeries: Array<ChartSerie>
    chartData: Array<ChartSerieNonTimeData> | Array<ChartSerieTimeData>
    isTimeBased: boolean
  } | null>(null)

  const setChartDataCb = useCallback(
    (
      chartSeries: Array<ChartSerie>,
      chartData: Array<ChartSerieNonTimeData> | Array<ChartSerieTimeData>,
      isTimeBased: boolean,
    ) => {
      setChartData({ chartSeries, chartData, isTimeBased })
    },
    [],
  )
  const getCSV = useCallback(() => {
    if (chartData) {
      return generateData(chartData.chartSeries, chartData.chartData, {
        isTimeBased: chartData.isTimeBased,
      })
    }

    return null
  }, [chartData])

  const contextValue = useMemo<ChartContextType>(() => {
    return {
      ...DEFAULT_CONTEXT_VALUES,
      setApi,
      setChartData: setChartDataCb,
      chartApi: api,
      getChartCSV: getCSV,
      _events,
      events: {
        add: (type, callback) => {
          _events.current[type].push(callback)
        },
        remove: (type, callback) => {
          _events.current[type] = _events.current[type].filter(
            (cb) => cb !== callback,
          )
        },
      },
    }
  }, [api, getCSV, setChartDataCb])

  return (
    <ChartContext.Provider value={contextValue}>
      {children}
    </ChartContext.Provider>
  )
}

export function withChartContext<P = {}>(
  WrappedComponent: FunctionComponent<P>,
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  function WithChartContext(props: P) {
    return (
      <ChartContextProvider>
        <WrappedComponent {...(props as JSX.IntrinsicAttributes & P)} />
      </ChartContextProvider>
    )
  }

  WithChartContext.displayName = `WithChartContext(${displayName})`

  return WithChartContext
}
