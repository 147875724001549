import numeral from 'numeral'

const priceFormatByAccuracy = {
  // Default accuracy
  normal: '$0.0a', // TODO: Remove or re-evaluate normal, which is not used apart from in ProjectsTable (deprecated)
  // Accuracy typically when dealing with eg. latest price
  increased: '$0.00a',
  // Not in use, but in the scale. Increased is formatted to this when dealing with fractional, but not build into logic.
  accurate: '$0.0000',
  // Very accurate price used with eg. Stablecoins price
  veryAccurate: '$0.000000',
} as const

const priceFormatToSmallestFloat = (format: string) => {
  const float = Number(format.replace(/\$|a/g, '').replace(/0$/, '1'))
  if (isNaN(float)) {
    // If regex were to fail. Could happen if someone adds weird format and doesn't adjust this
    throw `Could not calculate smallest usable numbery by accuracy, please re-check format (${format}) against the regex`
  }
  return float
}

export type PriceAccuracy = keyof typeof priceFormatByAccuracy

export type PriceOptions<T extends string | undefined = string> = {
  fallback?: T
  noUnit?: boolean
}

const defaultPriceOptions = <PriceOptions<string>>{
  fallback: '-',
  noUnit: false,
}

// TODO: maybe rename to formatPrice and remove old formatPrice
// TODO: remove priceOptions and create separate functions (depending on how things look after the todo above this)
export const getLabelForPrice = <T extends string | undefined = string>(
  value?: number | null,
  accuracy: PriceAccuracy = 'increased',
  priceOptions?: PriceOptions<T>,
): T extends string ? string : string | undefined => {
  const options = priceOptions ?? defaultPriceOptions // Due to types
  if (typeof value !== 'number') {
    return options.fallback as T extends string ? string : string | undefined
  }

  try {
    if (!value) {
      throw 'Zero value'
    }
    const absVal = Math.abs(value)
    let format: string = priceFormatByAccuracy[accuracy]

    const smallestUsableNumberByAccuracy = priceFormatToSmallestFloat(format)

    // We, apparently, want to opt out of displaying too small numbers?
    if (accuracy !== 'normal' && absVal < smallestUsableNumberByAccuracy) {
      throw 'Rounded to zero'
    }

    const isFractionalValue = absVal < 1
    if (
      accuracy !== 'normal' &&
      isFractionalValue &&
      accuracy !== 'accurate' &&
      accuracy !== 'veryAccurate'
    ) {
      // If format not already most accurate we use and not a normal one either, display fractional values with increased accuracy
      format = `${format.replace('a', '')}00`
    }

    const formattedValue = numeral(value).format(format)

    if (formattedValue === '$NaN' || formattedValue === 'N$aN') {
      /*
       * @url https://github.com/adamwdraper/Numeral-js/issues/596
       *
       * isAccuratePrice is always true here
       */
      throw 'Too small number to format'
    }

    return options.noUnit ? formattedValue.replace(/^\$/, '') : formattedValue
  } catch (error) {
    // Silence is golden
    // console.info(`Price fell back to zero due to "${error}" when input was "${value}"`)
    return `${options.noUnit ? '' : '$'}0.00`
  }
}

export const getLatestPriceLabel = (price?: null | number) => {
  // TODO: migrate to common function?
  const formattedPrice = numeral(price).format('$0,0.00[00]')

  const isRounded = Number(price) !== Number(numeral(formattedPrice).value())

  return {
    formattedPrice: isRounded ? `~${formattedPrice}` : formattedPrice,
    accuratePrice: isRounded ? `$${price}` : null,
  }
}

export const formatPrice = (value?: null | number) => {
  return getLabelForPrice(value, 'increased', {
    fallback: undefined,
    noUnit: true,
  })
}

export const getLabelForMcapAndRevenue = (value?: number | null) => {
  return getLabelForPrice(value)
}

/*
 * getLabelForNumber - Used for formatting Tokenholders, Active users etc.
 */
export const getLabelForNumber = (value?: number | null, fallback = '-') => {
  if (value == null || isNaN(value) || value === -1) {
    return fallback
  }
  return numeral(value).format('0[.]00a')
}

const isTooSmallFloatForPercentage = (value: number, threshold = 0.000001) =>
  // 0.00000001*100 is the smallest value numeral agrees to work with without retuning NaN
  !Number.isInteger(value) && Math.abs(value) < Math.max(0.00000001, threshold)

export const makePercentage = (
  value: number,
  valueFormat = '0,0.0',
): string => {
  const trailingZerosInFormat = valueFormat.split('.')[1]
  /*
   * Create maximum threshold based on requested format accuracy and limitations in the numeral library
   * We do this to avoid 2 cases
   *  1. Lib failing (returns NaN or just crashes, cant remember)
   *  2. Function returns eg. "0.00" when actual value is "0.001" but formatting doesn't allow that. In those cases the using party should resolve that to eg. "~0.00"
   */
  const threshold = Number.parseFloat(`0.0000${trailingZerosInFormat}1`)

  if (isTooSmallFloatForPercentage(value, threshold)) {
    return ''
  }

  const perc = value * 100
  if (perc > 1000000) {
    return numeral(perc).format('0.00e+0')
  }

  return numeral(perc).format(valueFormat)
}

export const getLabelForPercentage = (
  value?: number | null,
  csv?: boolean,
  double?: boolean,
) => {
  if (typeof value !== 'number' || isTooSmallFloatForPercentage(value))
    return csv ? '' : '-'

  const prefill = value > 0 ? '+' : ''
  if (double) {
    return prefill + makePercentage(value, '0,0.00') + '%'
  }
  return prefill + makePercentage(value) + '%'
}

export const formatX = (value?: number) => {
  if (typeof value !== 'number') return undefined

  return numeral(value).format('0,0.0')
}
export const getLabelForX = (value?: number | null) => {
  if (typeof value !== 'number') return '-'

  return formatX(value) + 'x'
}
export const getLabelForXProjectInfo = (
  value?: number | null,
  double?: boolean,
) => {
  if (value === null || value === undefined) return '-'
  if (value < 0) return 'N/A'
  if (double) {
    return numeral(value).format('0,00.00') + 'x'
  }
  return numeral(value).format('0,0.0') + 'x'
}

export type ColorStyle = { color: string }

export const getColor = (percentage?: number | null): ColorStyle => {
  if (!percentage) return { color: 'unset' }

  if (percentage >= 0) {
    return { color: '#00CF9D' }
  }
  return { color: '#EB5858' }
}

export const getLabelForMasterCSV = (
  value?: number | string | null,
  precision: '0.00' | '0.000' | '0.0000' = '0.00',
) => {
  if (value === undefined || value === null) return undefined
  return numeral(value).format(precision)
}

export const reverseNumeral = (value?: number | null) => {
  if (value) {
    return value * -1
  }

  return 0
}

export const canUseNumeral = (value?: string | number) => {
  // Check if we can use numeraljs for formatting values because numeraljs returns NaN
  // if value is below 0.000000. If value is below, return false and just show the value as it is e.g 1e-6

  // can return true if value is undefined: numeral(undefined).value() === 0
  if (!value) return true

  return Math.abs(Number(value)) >= 1e-6
}
