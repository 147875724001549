import { Icon } from '@tokenterminal/ui/Icon/Icon'
import { Row } from '@tokenterminal/ui/Row'
import { Stack } from '@tokenterminal/ui/Stack'
import { Text, type TextProps } from '@tokenterminal/ui/Text'

// TODO: Move this out of Chart and to a more common place

type EmptyStateProps = {
  icon?: any
  headingText: string
  bodyText: string
  actions?: any
  size?: 'small' | 'medium'
}

const sizeLookup: Record<
  string,
  {
    heading: TextProps
    body: TextProps
    icon: {
      height: number
      width: number
    }
  }
> = {
  small: {
    heading: {
      size: '16',
    },
    body: {
      size: '14',
    },
    icon: {
      width: 24,
      height: 24,
    },
  },
  medium: {
    heading: {
      size: '20',
    },
    body: {
      size: '14',
    },
    icon: {
      width: 32,
      height: 32,
    },
  },
}

const EmptyState = ({
  icon,
  headingText,
  size = 'medium',
  bodyText,
  actions,
}: EmptyStateProps) => {
  const {
    heading: headingProps,
    body: bodyProps,
    icon: iconProps,
  } = sizeLookup[size]

  return (
    <Stack
      gap="5x"
      alignItems="center"
      justifyContent="center"
      paddingX="16x"
      paddingY="16x"
    >
      {icon && <Icon as={icon} {...iconProps} style={{ opacity: 0.5 }} />}
      <Stack alignItems="center" gap="2x" textAlign="center" marginBottom="2x">
        <Text as="h3" fontWeight="500" {...headingProps}>
          {headingText}
        </Text>
        <Text
          color="muted"
          size="14"
          {...bodyProps}
          style={{ maxWidth: '380px' }}
        >
          {bodyText}
        </Text>
      </Stack>
      {actions && <Row>{actions}</Row>}
    </Stack>
  )
}

export default EmptyState
