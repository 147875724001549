export const TREND_COLOR_SET = {
  positive: '#1AFFAC',
  negative: '#FF5E1A',
}

export const COLORS_LARGE_SET = [
  '#1AFFAC',
  '#4869ef',
  '#493674',
  '#ffafff',
  '#f6f2e5',

  '#b7cbff',
  '#607092',
  '#f1e880',
  '#ffb13e',
  '#a94381',

  '#be8eeb',
  '#003962',
  '#72c6cd',
  '#d7e16a',
  '#557543',

  '#b68546',
  '#ca8b91',
  '#753436',
  '#d34a3f',
  '#464936',
]

export const getColors = (length: number) => {
  return COLORS_LARGE_SET.slice(0, length)
}
