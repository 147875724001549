import { type CompositionChartPayload } from "@tokenterminal/tt-analytics-api-types/dist/api/charts"
import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { atom } from "jotai"
import { type Atom } from "jotai/ts3.8/vanilla"
import { atomFamily } from "jotai/utils"
import { isDefined } from "../../../../utils/isDefined"
import { type Result, unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { generateId } from "../../utils/generate-id"
import { getProjectsFromFilters } from "../../utils/get-projects-from-filter"
import { chainsAtom, getProjectsByMetricAtom, projectsAtom } from "./meta-atoms"
import { getMetricCompositionChartDataByMetricAndChainAtom } from "./metric-composition-data-atom"
import { getAggregatedChartDataByMetricAndBlockchainAtom } from "./metrics-blockchain-data-atom"

export const getSerieIdsFromChartSettingAtom = atomFamily(
  (chartSettingAtom: Atom<Promisable<CustomChartSerie>>) => {
    const serieIdsFromChartSettingAtom = atom(async (get) => {
      const chartSetting = await get(chartSettingAtom)

      if (!chartSetting.metric) {
        return []
      }

      if (!chartSetting.groupBy) {
        return [generateId(chartSetting.id, "aggregated")]
      }

      const blockchainFilter = chartSetting.filters.find(
        (filter) => filter.type === "blockchain"
      )
      const marketSectorFilter = chartSetting.filters.find(
        (filter) => filter.type === "market_sector"
      )

      if (blockchainFilter) {
        let composedData = await get(
          getAggregatedChartDataByMetricAndBlockchainAtom({
            interval: "max",
            metric: chartSetting.metric.replace(/-/g, "_"),
            chainIds: blockchainFilter.values,
            projectIds: getProjectsFromFilters(chartSetting.filters),
          })
        )

        if (composedData.err) {
          return []
        }

        if (
          chartSetting.filters.length === 2 &&
          marketSectorFilter &&
          composedData.ok
        ) {
          const projectMeta = await get(projectsAtom)
          const projectsMetaDictionary = toDictionary(
            unwrapWithData(projectMeta),
            "data_id"
          )
          // clone date to avoid mutation
          // @ts-ignore - types are bad
          composedData = JSON.parse(
            JSON.stringify(composedData)
          ) as unknown as Result<CompositionChartPayload, null>
          composedData.ok!.data = composedData.ok!.data.filter((row) => {
            const projectMeta = projectsMetaDictionary.get(row.data_id!)
            if (!projectMeta) {
              return false
            }

            return projectMeta?.flattened_tags?.some((tag) =>
              marketSectorFilter.values.includes(tag)
            )
          })
        }

        if (chartSetting.groupBy === "chain") {
          return [
            ...new Set(
              composedData
                .ok!.data.map((row) => {
                  return generateId(chartSetting.id, row.chain_id!)
                })
                .filter(isDefined)
            ),
          ]
        } else {
          const [projectsMeta, chainsMeta] = await Promise.all([
            get(projectsAtom),
            get(chainsAtom),
          ])
          const projectsDictionary = toDictionary(
            unwrapWithData(projectsMeta),
            "data_id"
          )
          const chainsDictionary = toDictionary(
            unwrapWithData(chainsMeta),
            "chain_id"
          )
          const sumById = composedData.ok!.data.reduce(
            (acc, row) => {
              if (
                !projectsDictionary.has(row.data_id!) ||
                !chainsDictionary.has(row.chain_id!)
              ) {
                return acc
              }

              const key = generateId(
                chartSetting.id,
                `${row.chain_id}-${row.data_id}`
              )
              acc[key] = acc[key] || 0
              acc[key] += row.value

              return acc
            },
            {} as Record<string, number>
          )

          const ids = Array.from(
            new Set(
              composedData
                .ok!.data.map((row) => {
                  if (
                    !projectsDictionary.has(row.data_id!) ||
                    !chainsDictionary.has(row.chain_id!)
                  ) {
                    return undefined
                  }

                  return generateId(
                    chartSetting.id,
                    `${row.chain_id}-${row.data_id}`
                  )
                })
                .filter(isDefined)
            )
          )

          return ids.sort((a, b) => {
            return (sumById[b] ?? 0) - (sumById[a] ?? 0)
          })
        }
      }

      if (marketSectorFilter && chartSetting.filters.length === 1) {
        if (chartSetting.groupBy === "project") {
          const projectsMeta = await get(
            getProjectsByMetricAtom(chartSetting.metric)
          )
          const projects = projectsMeta.filter((project) => {
            return project.flattened_tags.some((tag) =>
              marketSectorFilter.values.includes(tag)
            )
          })

          return projects.map((project) =>
            generateId(chartSetting.id, project.data_id)
          )
        } else {
          return marketSectorFilter.values.map((marketSector) => {
            return generateId(chartSetting.id, marketSector)
          })
        }
      }

      if (
        chartSetting.filters.length === 1 &&
        chartSetting.groupBy === "project"
      ) {
        return chartSetting.filters[0]!.values.map((filterValue) => {
          return generateId(chartSetting.id, filterValue)
        })
      }

      if (chartSetting.groupBy === "project") {
        return getProjectsFromFilters(chartSetting.filters).map((project) => {
          return generateId(chartSetting.id, project)
        })
      }

      if (chartSetting.groupBy === "chain") {
        const composedData = await get(
          getMetricCompositionChartDataByMetricAndChainAtom({
            composeBy: "chain",
            interval: "24h",
            metric: chartSetting.metric,
            projects: getProjectsFromFilters(chartSetting.filters),
          })
        )

        if (composedData.err) {
          return []
        }

        return composedData.ok.data
          .sort((a, b) => {
            return b.value - a.value
          })
          .map((chain) => {
            return generateId(
              chartSetting.id,
              `${chain.data_id}-${chain.composition_value}`
            )
          })
      }

      return [chartSetting.title]
    })

    if (process.env.NODE_ENV === "development") {
      serieIdsFromChartSettingAtom.debugLabel = `serieIdsFromChartSettingAtom(${chartSettingAtom.debugLabel ?? ""})`
    }

    return serieIdsFromChartSettingAtom
  }
)
