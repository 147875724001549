import { type GranularityType } from '../Chart'
import { type ChartSerieTimeData } from '../useHighchartOptions'

export function hideDataBeforeCurrentPeriod(
  data: ChartSerieTimeData,
  granularity: GranularityType,
): ChartSerieTimeData {
  if (!data.length) {
    return []
  }
  const today = new Date()
  // use timezone etc from the server
  const dateToStartDroppingFrom = new Date(data[data.length - 1][0])
  dateToStartDroppingFrom.setUTCDate(today.getUTCDate())
  dateToStartDroppingFrom.setUTCMonth(today.getUTCMonth())
  dateToStartDroppingFrom.setUTCFullYear(today.getUTCFullYear())
  dateToStartDroppingFrom.setUTCHours(0, 0, 0, 0)

  switch (granularity) {
    case 'quarter': {
      dateToStartDroppingFrom.setUTCDate(1)
      dateToStartDroppingFrom.setUTCMonth(
        Math.floor(dateToStartDroppingFrom.getUTCMonth() / 3) * 3,
      )
      break
    }
    case 'month': {
      dateToStartDroppingFrom.setUTCDate(1)
      break
    }
    case 'week': {
      dateToStartDroppingFrom.setUTCDate(
        dateToStartDroppingFrom.getUTCDate() -
          dateToStartDroppingFrom.getUTCDay() +
          1,
      )
      break
    }
  }

  const timestampToStartDroppingFrom = dateToStartDroppingFrom.getTime()

  return data.filter(([timestamp]) => timestamp < timestampToStartDroppingFrom)
}
