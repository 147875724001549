import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type Atom, useAtomValue } from "jotai"
import { useMemo } from "react"
import { getTooltipOptionsFromChartSettingsAtom } from "../store/chart/tooltip-options-atom"

export function useTooltipOptions(
  chartSettingsAtom: Atom<Promisable<Array<CustomChartSerie>>>
) {
  const tooltipOptions = useAtomValue(
    useMemo(
      () => getTooltipOptionsFromChartSettingsAtom(chartSettingsAtom),
      [chartSettingsAtom]
    )
  )

  return tooltipOptions
}
