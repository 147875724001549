import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import { type Atom, useAtomValue } from "jotai"
import { useMemo } from "react"
import { getSeriesDataFromChartSettingsAtom } from "../store/chart/series-data-atom"

export function useSeriesData(
  chartSettingsAtom: Atom<Promisable<Array<CustomChartSerie>>>,
  {
    zoom,
  }: {
    zoom: Interval
  }
) {
  const seriesData = useAtomValue(
    useMemo(
      () => getSeriesDataFromChartSettingsAtom({ chartSettingsAtom, zoom }),
      [chartSettingsAtom, zoom]
    )
  )

  return seriesData
}
