import { type GranularityType } from '../Chart'
import { type ChartSerieTimeData } from '../useHighchartOptions'

export function fixCumulativeData(
  data: ChartSerieTimeData,
  granularity: GranularityType,
): ChartSerieTimeData {
  let sum = 0
  const lastIndex = data.length - 1

  return (
    data
      .slice() // copy array
      // make sure data is sorted
      .sort((a, b) => {
        return Number(a[0]) - Number(b[0])
      })
      .map((row, index) => {
        sum += row[1] ?? 0
        let value = 0

        if (row[1] === null) {
          return row
        }

        switch (granularity) {
          // quarter we mark all values as 0 but add the cumulative value when it's the last day of the quarter
          case 'quarter': {
            if (lastIndex === index) {
              value = sum
              break
            }

            const timestampAsDate = new Date(data[index + 1][0])
            if (
              timestampAsDate.getUTCDate() === 1 &&
              timestampAsDate.getUTCMonth() % 3 === 0
            ) {
              value = sum
            }

            break
          }
          // monthly we mark all values as 0 but add the cumulative value when it's the last day of the month
          case 'month': {
            if (
              (lastIndex !== index &&
                data[index + 1] &&
                new Date(data[index + 1][0]).getUTCDate() === 1) ||
              lastIndex === index
            ) {
              value = sum
            }

            break
          }
          // weekly we mark all values as 0 but add the cumulative value when it's sunday
          case 'week': {
            if (new Date(row[0]).getDay() === 0 || lastIndex === index) {
              value = sum
            }
            break
          }
          default: {
            value = sum
          }
        }

        return [row[0], value]
      })
  )
}
